import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService) { }

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     if (localStorage.getItem('currentUser')) {
    //         // logged in so return true
    //         return true;
    //     }
    //
    //     // not logged in so redirect to login page with the return url
    //     this.router.navigate(['/authentication/login'], {queryParams: { returnUrl: state.url }});
    //     return false;
    // }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      return true;
      // if (sessionStorage.getItem('gotMe') == null || sessionStorage.getItem('gotMe') == undefined) {
      //   const promise = new Promise<boolean>((resolve, reject) => {
      //     this.authenticationService.getMe().pipe().subscribe(user => {
      //       console.log('here', user)
      //       console.log('after user')
      //       if (user.paymentInvalid == true || user.deleted || user.disbled) {
      //         console.log('Users payment was invalid, returning to signin.');
      //         localStorage.removeItem('currentUser');
      //         this.router.navigate(['/authentication/login'], {queryParams: { returnUrl: state.url }});
      //         return resolve(false);
      //
      //       } else {
      //         localStorage.setItem('currentUser', JSON.stringify(user));
      //         sessionStorage.setItem('gotMe', 'true');
      //         return resolve(true);
      //       }
      //       }, error => {
      //       console.log(error)
      //       localStorage.removeItem('currentUser');
      //       this.router.navigate(['/authentication/login'], {queryParams: { returnUrl: state.url }});
      //       return resolve(false);
      //
      //     });
      //   });
      //   return promise;
      // } else {
      //   return true;
      // }
    } else {
      this.router.navigate(['/authentication/login'], {queryParams: { returnUrl: state.url }});
      return false;
    }
  }

}
