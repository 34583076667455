// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  // apiUrl: 'http://dev.elvt.cloud:3001/api/v1',
  apiUrl: '/api/v1',
  // chargifyJsKey: 'chjs_thq9kkg8x8nckbmzwxp57fbs',
  // kyipApiKey: 'lISClRSY4rlXa5DlRnqz',
  // chargifySite: 'https://kyip-test-clone-v2.chargify.com'

  chargifyJsKey: 'chjs_ksss9dp6skmvrrrngkdp255s',
  kyipApiKey: 'lISClRSY4rlXa5DlRnqz',
  chargifySite: 'https://kyip-test.chargify.com'
};
