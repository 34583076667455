/**
 * Created by Aran on 1/10/2020.
 */
import { Injectable } from "@angular/core";
import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";


@Injectable({ providedIn: 'root' })
export class AuthChildGuard implements CanActivateChild {

  constructor(private router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      if(user.isadmin) {
        if(!state.url.includes('admin') &&
          !state.url.includes('partner') &&
          !state.url.includes('dealer')
        ) {
          this.router.navigate(['/admin/overview']);
        }
      }
      else if(user.isPartner) {
        if(!state.url.includes('partner') &&
          !state.url.includes('dealer')
        ) {
          this.router.navigate(['/partners/dashboard']);
        }
      }
      else{
        if(!state.url.includes('dealer')) {
          this.router.navigate(['/dealers']);
        }
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/authentication/login'], {queryParams: { returnUrl: state.url }});
    return false;
  }
}
