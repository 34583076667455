import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements  HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        if (user && user.token) {
            const re = 's3.us-west-2.amazonaws.com'; // if request is trying to upload to s3 do not intercept header
            const re2 = 's3.amazonaws.com'; // if request is trying to upload to s3 do not intercept header
            // Exclude interceptor for login request:
            if (request.url.search(re) === -1 && request.url.search(re2) === -1) {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + user.token
                    }
                });
            }

        }

        return next.handle(request);
    }
}
